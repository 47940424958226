import styled, { css } from 'styled-components';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean; variant: VariantType['variant'] }>`
  ${({ theme, checked, variant = 'primary' }) => {
    const { backgroundColor, fontColor } = getColorMode(variant);
    return css`
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      border: ${theme.borderWidths.ui} solid ${theme.colors[fontColor]};
      transition: all 150ms;
      background: ${checked ? theme.colors[backgroundColor] : 'transparent'};

      ${Icon} {
        visibility: ${checked ? 'visible' : 'hidden'};
        stroke: ${theme.colors[fontColor]};
      }
    `;
  }}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;
