import React, { ChangeEvent } from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from '@components/checkbox/checkbox.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Text from '@components/text';
import { RichTextBlock } from 'prismic-reactjs';

interface CheckboxProps {
  id: string;
  className?: string;
  variant: VariantType['variant'];
  checked: boolean;
  label: RichTextBlock[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ id, className, checked, onChange, variant, label }: CheckboxProps) => {
  const { fontColor } = getColorMode(variant);

  return (
    <div>
      <label htmlFor={id}>
        <CheckboxContainer className={className}>
          <HiddenCheckbox id={id} checked={checked} onChange={onChange} />
          <StyledCheckbox checked={checked} variant={variant}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          <Text fontSize="small" richText={label} fontColor={fontColor} />
        </CheckboxContainer>
      </label>
    </div>
  );
};

export default Checkbox;
