import React, { ChangeEvent, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import Cookies from 'js-cookie';
import { RichTextBlock } from 'prismic-reactjs';
import styled, { ThemeProvider, css } from 'styled-components';
import getThemeConfig from '@layouts/helpers/get-theme-config';
import { ThemeConfigType } from '@layouts/helpers/theme-config.type';
import GlobalStyle from '@components/global-style';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { defaultTheme } from '@styles/theme.styled';
import { LogoProps } from '@interfaces/styles';
import SEO from '@components/seo';
import Text from '@components/text';
import { ImageProps } from '@components/image';
import Button from '@components/button';
import Checkbox from '@components/checkbox';

const Container = styled.div<{ backgroundColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, backgroundColor }) => css`
    @keyframes drop {
      from {
        transform: scale(1.125);
        opacity: 0;
        filter: blur(4rem);
      }
      to {
        transform: scale(1);
        opacity: 1;
        filter: blur(0);
      }
    }

    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    -ms-overflow-style: none;
    transform-origin: 100% 100% 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    background-color: ${theme.colors[backgroundColor]};
  `}
`;

const Wrapper = styled.div`
  transform: scale(1.25) translateY(8rem) rotateX(48deg);
  opacity: 0;
  animation: 0.375s drop 1s forwards ease-in-out;
  perspective-origin: center center;
  perspective: 1800;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.div<LogoProps>`
  ${({ theme, imageUrl, $color }) => css`
    width: 20.5rem;
    height: 9rem;
    background-color: ${theme.colors[$color]};
    mask: url(${imageUrl}) no-repeat center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat center / contain;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 15rem;
      height: 6.6rem;
    }
  `}
`;

const MessageIcon = styled.div<LogoProps>`
  ${({ theme, imageUrl, $color }) => css`
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${theme.colors[$color]};
    mask: url(${imageUrl}) no-repeat center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat center / contain;
  `}
`;

const Title = styled(Text)`
  margin: 2rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

const StyledButton = styled(Button)<{ margin?: string }>`
  ${({ margin }) => css`
    width: 8rem;
    margin: ${margin || 0};
  `}
`;

const TwoItemsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  gap: 0.5rem;
`;

export interface AgeGateInterface {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: {
        alt: string;
        url: string;
      };
    };
  };
  prismicThemeConfig: {
    data: ThemeConfigType;
  };
  prismicAgeGate: {
    data: {
      page_title: {
        text: string;
      };
      meta_description: {
        text: string;
      };
      meta_image: {
        url: string;
        alt: string;
      };
      color_mode: VariantType['variant'];
      title: {
        text: string;
      };
      message_image: ImageProps;
      message: {
        richText: RichTextBlock[];
      };
      image: ImageProps;
      checkbox_label: {
        richText: RichTextBlock[];
      };
    };
  };
}

const AgeGate = (): JSX.Element => {
  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    prismicThemeConfig: { data },
    prismicAgeGate: {
      data: {
        page_title,
        meta_description,
        meta_image,
        color_mode,
        title,
        message_image,
        message,
        image,
        checkbox_label,
      },
    },
  }: AgeGateInterface = useStaticQuery(
    graphql`
      query AgeGateQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
        prismicThemeConfig {
          ...ThemeConfigFragment
        }
        prismicAgeGate {
          data {
            page_title {
              text
            }
            meta_description {
              text
            }
            meta_image {
              url
              alt
            }
            color_mode
            title {
              text
            }
            message_image {
              gatsbyImageData
              url
              alt
            }
            message {
              richText
            }
            image {
              gatsbyImageData
              url
              alt
            }
            checkbox_label {
              richText
            }
          }
        }
      }
    `
  );

  const theme = getThemeConfig(data);
  const { backgroundColor, fontColor } = getColorMode(color_mode);

  const [isMessageVisible, setIsMessageVisible] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(true);

  const setCookie = () => {
    if (isCheckboxChecked) {
      Cookies.set('age-gate', 'approved', { expires: 30 });
    } else {
      Cookies.set('age-gate', 'approved');
    }
    void navigate(-1);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <SEO
        title={page_title.text}
        description={meta_description.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      <GlobalStyle />
      <Container backgroundColor={backgroundColor}>
        <Wrapper>
          {image.url && <Logo imageUrl={image.url} $color={fontColor} />}
          <Title type="title" fontSize="heading6" stringText={title.text} fontColor={fontColor} />
          <ButtonContainer>
            <StyledButton
              label="No"
              outlined
              size="medium"
              variant={color_mode}
              margin="0 1rem 0 0"
              onClick={() => setIsMessageVisible(true)}
            />
            <StyledButton
              label="Yes"
              outlined
              size="medium"
              variant={color_mode}
              onClick={setCookie}
            />
          </ButtonContainer>
          {isMessageVisible && (
            <TwoItemsContainer>
              {message_image.url && <MessageIcon imageUrl={message_image.url} $color={fontColor} />}
              <Text fontSize="small" richText={message.richText} fontColor={fontColor} />
            </TwoItemsContainer>
          )}
          <Checkbox
            variant={color_mode}
            id="checkbox"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
            label={checkbox_label.richText}
          />
        </Wrapper>
      </Container>
    </ThemeProvider>
  );
};

export default AgeGate;
